<template>
  <v-col class="ma-0 pa-0">
    <v-data-table
      :headers="(company.type === 'A' && company.deliveryMethod !== 'PR') ? headersA : headers"
      :items="contracts"
      :pagination.sync="pagination"
      :items-per-page="5"
      mobile-breakpoint="0"
      style="backgroud-color: transparent"
    >
      <!--      <template v-slot:header="{ props: { headers } }">-->
      <!--        <tr class="text-left pa-0">-->
      <!--          <th v-for="header in headers" :key="header.text" class="text-left pa-0"-->
      <!--              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"-->
      <!--              @click="changeSort(header.value)" :style="'width:' + header.width + 'px'">-->
      <!--            <span class="header font-weight-bold shades&#45;&#45;text text&#45;&#45;black">{{ header.text }}</span>-->
      <!--            <v-icon small>arrow_upward</v-icon>-->
      <!--          </th>-->
      <!--        </tr>-->
      <!--      </template>-->
      <template v-slot:item="{ item }">
        <tr>
          <td class="pa-0">
            <v-row @click="influencerDetailDlg(item?.Influencer)" class="mt-2">
              <div v-if="item.level === 'diamond'">
                <diamond-icon class="mr-2"/>
              </div>
              <div v-else>
                <v-icon size="20" :color="item.level === 'diamond' ? '#628AE6' : item.level" class="mr-2" >mdi-hexagon</v-icon>
              </div>
              <span v-if="company.type === 'S'">{{ item.username }}</span>
              <a v-else target="_blank" :href="`https://blog.naver.com/${item.outerId}`" style="color: rgba(0,0,0,.87);">{{ item.username }}</a>
              <p v-if="company.type === 'A'">({{ phoneNumbers[item.user] }})</p>
            </v-row>
          </td>
          <td class="pa-0">{{ new Date(company.type === 'A' ? item.selectedAt : item.date0).toLocaleDateString() }}</td>
          <td class="pa-0">
            <span v-if="item.currentState == -1">이용 취소</span>
            <a
              href="#"
              v-else-if="item.link"
              @click="showReview(item)"
              class="row_content"
            >
              등록
            </a>
            <span
              v-else-if="!item.link && item.receiptReview"
              class="row_content2"
              >등록
            </span>
            <span v-else-if="item.extended">연장</span>
            <span v-else-if="company.type === 'A' && company.category.firstName === '매장' && !item.date0">방문 전</span>
            <span v-else>대기</span>
          </td>
          <td class="pa-0">
            {{
              item.date1 && item.currentState === 1
                ? new Date(item.date1).toLocaleDateString()
                : '-'
            }}
          </td>
          <td class="row_content pa-0">
            <span v-if="item.currentState === 0"> - </span>
            <a
              href="#"
              v-if="item.currentState === 1"
              @click="review(item)"
              class="row_content"
              >평가</a
            >
            <span v-else>{{ item.mark }}/5</span>
          </td>
          <td>
            <div v-if="tap === 0">
              <a
                href="#"
                v-if="item.receiptReview"
                @click="showReceiptReview(item)"
                class="row_content"
              >
                등록
              </a>
              <span v-else-if="item.receiptOnly && !item.receiptReview"
                >대기</span
              >
              <span v-else>-</span>
            </div>
            <div v-else>
              <a
                href="#"
                v-if="item.receiptReview"
                @click="showReceiptReview(item)"
                class="row_content"
              >
                등록
              </a>
              <span v-else>-</span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-col class="text-right pa-0 ma-0">
      <download-excel :data="contracts" :fields="json_fields" name="review.xls">
        <v-btn color="#61c9a8" class="ma-0 white--text"
          >엑셀파일 다운로드</v-btn
        >
      </download-excel>
    </v-col>
    <RateDialog
      v-model="showDialog"
      :contract="contract"
      @complete="$emit('complete')"
    ></RateDialog>
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail" :openInfluencer="openInfluencer">
      </InfluencerDetail>
    </v-row>
  </v-col>
</template>

<script>
import JsonExcel from 'vue-json-excel';
// import XLSX from 'xlsx';
import Vue from 'vue';
import companyMixin from "@/components/shared/companyMixin";
import InfluencerDetail from "@/components/campaign/dialog/InfluencerDetail.vue";

export default {
  name: 'Store',
  mixins: [companyMixin],
  props: ['contracts', 'tap'],
  components: {
    InfluencerDetail,
    RateDialog: () => import('./RateDialog'),
    diamondIcon: () => import('@/assets/img/regads/diamond.svg'),
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      phoneNumbers: {},
      pagination: {
        sortBy: 'name',
      },
      headers: [
        { text: '등급 ∙ 회원명', value: 'level', width: 120 },
        // { text: '이용 채널', value: 'channel', width: 110},
        { text: '이용 일자', align: 'left', value: 'date0', width: 110 },
        { text: '리뷰상태', value: 'currentState', width: 110 },
        { text: '리뷰 등록일자', value: 'date1', width: 120 },
        { text: '리뷰 평가', value: 'currentState', width: 110 },
        { text: '영수증 리뷰', value: 'receiptReview', width: 110 },
      ],
      headersA: [
        { text: '등급 ∙ 회원명', value: 'level', width: 120 },
        // { text: '이용 채널', value: 'channel', width: 110},
        { text: '이용 일자', align: 'left', value: 'date0', width: 110 },
        { text: '리뷰상태', value: 'currentState', width: 110 },
        { text: '리뷰 등록일자', value: 'date1', width: 120 },
        { text: '리뷰 평가', value: 'currentState', width: 110 }
      ],
      showDialog: false,
      contract: {},
      json_fields: {
        '업데이트 일자': {
          callback: () => {
            return Vue.moment(new Date())
              .tz('Asia/Seoul')
              .format('YYYY-MM-DD HH:MM');
          },
        },
        광고명: 'companyName',
        회원명: 'username',
        등급: 'level',
        채널명: {
          field: 'type',
          callback: (value) => {
            if (value === 'insta') {
              return '인스타';
            } else {
              return '네이버';
            }
          },
        },
        '이용 일자': {
          callback: (value) => {
            return Vue.moment(value.date0)
              .tz('Asia/Seoul')
              .format('YYYY-MM-DD HH:MM');
          },
        },
        '리뷰 상태': {
          callback: (value) => {
            if (value.currentState === -1) {
              return '이용취소';
            } else if (value.currentState === 0) {
              return '등록대기';
            } else if (value.currentState === 1) {
              return '등록완료';
            } else if (value.currentState === 2) {
              return '평가완료';
            } else {
              return '확인중';
            }
          },
        },
        '리뷰 등록일자': {
          callback: (value) => {
            return value.date1
              ? Vue.moment(value.date1)
                  .tz('Asia/Seoul')
                  .format('YYYY-MM-DD HH:MM')
              : '';
          },
        },
        '리뷰 평가일자': {
          callback: (value) => {
            return value.date2
              ? Vue.moment(value.date2)
                  .tz('Asia/Seoul')
                  .format('YYYY-MM-DD HH:MM')
              : '';
          },
        },
        '리뷰 링크': {
          callback: (value) => {
            if (value.type === 'insta') {
              return value.link;
            } else {
              return value.link
                ? 'https://blog.naver.com/' +
                    String(value.userEmail || '').split('@')[0] +
                    '/' +
                    value.link
                : '';
            }
          },
        },
        '리뷰 평점': 'mark',
      },
      openInfluencer: {},
      openInfluencerDetail: false,
    };
  },
  watch: {
    tap() {
      if (this.tap === 1) {
        let idx = this._.findIndex(this.headers, { value: 'receiptReview' });
        if (idx > -1) {
          this.$set(this.headers, idx, {
            text: '스토어 리뷰',
            value: 'receiptReview',
            width: 110,
          });
        }
      } else {
        let idx = this._.findIndex(this.headers, { value: 'receiptReview' });
        if (idx > -1) {
          this.$set(this.headers, idx, {
            text: '영수증 리뷰',
            value: 'receiptReview',
            width: 110,
          });
        }
      }
    },
    async contracts() {
      if(this.company.type === 'A'){
        const promiseMap = this.contracts.map(c => this.axios.get(`/user?uid=${c.user}`));
        const all = await Promise.all(promiseMap);

        const temp = {};

        all.forEach(result => {
          const user = result.data.users[0];
          temp[user.uid] = user.phone;
        });

        this.phoneNumbers = temp;
      }
    }
  },
  methods: {
    async influencerDetailDlg(item) {
      this.openInfluencerDetail = true;
      this.openInfluencer = item;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    showReview(contract) {
      let reviewWindow = window.open();
      if (contract.type === 'insta') {
        reviewWindow.location = contract.link;
      } else {

        reviewWindow.location =
          'https://blog.naver.com/' +
          String(contract['outerId'] || contract['userEmail'] || '').split('@')[0] +
          '/' +
          contract.link;
      }
    },
    showReceiptReview(contract) {
      let reviewWindow = window.open();
      reviewWindow.location = contract.receiptReview;
    },
    review(contract) {
      this.showDialog = true;
      this.contract = contract;
    },
  },
  async created() {
    if (this.company.type === 'A' && this.company.deliveryMethod === 'PR') {
      this.headers[5] = {
        text: '스토어 리뷰',
        value: 'receiptReview',
        width: 110,
      };
    }

    if(this.company.deliveryMethod === 'PR'){
      this.json_fields = {...this.json_fields, '스토어 리뷰': 'receiptReview'}
    }

    if (this.company.type === 'A') {
      this.headersA[1] = {
        text: '선정 일자',
        value: 'selectedAt',
        align : 'left',
        width: 110,
      };
    }
  }
};
</script>

<style scoped>
.header {
  font-size: 13px;
}

.row_content2 {
  color: #bd1328;
  color: var(--rouge-color);
  letter-spacing: -0.9px;
}
</style>
